/*
    Geo related common functionality
*/
import SmartyStreetsSDK from "../libs/smarty_street/smartystreets-sdk-1.6.2.min";

frappe.provide("geo.utils");

$.extend(geo.utils, {
    get_zip_code_info: function(zip_code){
        return new Promise((resolve, reject)=>{
            frappe.xcall("bond_assets.geo.utils.get_zip_code_info", {
                zip_code: zip_code,
            }).then((res)=>{
                resolve(res);
            })
        });
    },
    get_client: function(){
        return new Promise((resolve, reject) => {
            frappe.xcall("bond_assets.geo_api.api.get_address_settings", {
            }).then((res) =>{
                const credentials = new SmartyStreetsSDK.core.SharedCredentials(res.smarty_street_web_key);
                let builder = new SmartyStreetsSDK.core.ClientBuilder(credentials);
                let autocomplete = builder.buildUsAutocompleteProClient();
                let zipcode_builder = builder.buildUsZipcodeClient();
                if(bond_assets.dev.is_developer_mode()){
                    builder.debug = true;
                }
                builder.withLicenses([res.auto_complete_license]);
                resolve({
                    "autocomplete": autocomplete,
                    "zipcode_builder": zipcode_builder
                });
            });
        });
    },
    has_error: function(r){
        if(r && r.statusCode == 401){
            return true;
        }
        return false;
    },
    validate_service_type: function(args){
        return new Promise((resolve, reject) => {

            if(args.zip_code.length !== 5){
                reject(__("Please enter valid Zip Code"));
                return false;
            }

            frappe.call({
                method: "bond_landing.landing_page.forms.get_zip_code_info",
                args:args,
                callback: (res)=>{
                    
                    if($.isEmptyObject(res.message) || !res.message){
                        reject(__("This is not a U.S. Zip Code"));
                        return;
                    }
                    frappe.call({
                        method: "bond_landing.landing_page.forms.has_enabled_services",
                        args:{
                            line_of_insurance: args.line_of_insurance,
                            zip_code: args.zip_code,
                            city_info: res.message
                        },
                        freeze: true,
                        callback: (res)=>{
                            res.prev_res = res;
                            resolve(res);
                        }
                    });
                },
                freeze: true,
            });
        });
    }
});